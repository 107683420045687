<script setup lang="ts">
import Modal from "./Modal.vue"

const props = defineProps({
  "show": { type: Boolean, default: false },
  "maxWidth": { default: "2xl" },
  "closeable": { type: Boolean, default: true }
})

const emit = defineEmits(["close", "open"])

const close = () => {
    emit("close")
}

const open = () => {
    emit("open")
}
</script>

<template>
    <Modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        @open="open"
        @close="close">
        <div class="px-6 py-4">
            <div class="text-lg">
                <slot name="title" />
            </div>

            <div class="mt-4">
                <slot name="content" />
            </div>
        </div>

        <div class="flex flex-row justify-end bg-gray-100 px-6 py-4 text-right">
            <slot name="footer" />
        </div>
    </Modal>
</template>
